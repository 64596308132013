@import 'semantic-overrides.g.scss';

html {
    overflow: auto;
}

body {
    background-color: var(--ky-lightblue-05) !important;
    background: url(https://cdn.keeyns.com/auth0v2/background_left.png) left center no-repeat, url(https://cdn.keeyns.com/auth0v2/background_right.png) right center no-repeat;
    overflow-x: auto;
    overflow-y: auto;

    > #root {
        height: 100%
    }
}

// Headers
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
}

h1 {
    font-size: 32px;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 20px;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 16px;
}

h6 {
    font-size: 14px;
}

// Shows the pointer cursor on an element
.clickable {
    cursor: pointer !important;
}

.clickableText {
    text-decoration: underline;
}