.ui.ui.ui.input {
    > input {
        border: none;
        background-color: var(--ky-black-05);
        border-radius: var(--spacing-4xl);

        &::placeholder {
            color: var(--ky-black-50);
            opacity: 1; /* Firefox */
        }

        ::-ms-input-placeholder { /* Edge 12-18 */
            color: var(--ky-black-50);
        }
    }

    > i {
        color: var(--ky-black-50);
    }
}

// Tooltips with dark theme
.ui.ui.ui.ui.ui.popup.visible,
[data-position][data-tooltip] {
    border-radius: 10px;
    white-space: pre;
    color: var(--ky-basic-white);

    &::before {
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        background: var(--bg-tooltip);
    }

    &::after {
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        background: var(--bg-tooltip);
        border-radius: 10px;
        white-space: pre;
    }
}

[data-tooltip]::after {
    color: var(--ky-basic-white);
}

// Use font awesome 5 pro
i.icon {
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
}

// All buttons
.ui.ui.ui.ui.ui.button {
    box-shadow: unset;
    border-radius: var(--spacing-sm);
    transition: all .2s ease-in-out;

    &.plain {
        background-color: var(--ky-basic-white);

        &:hover {
            background-color: var(--fg-hover);
        }

        &:disabled {
            color: var(--fg-passive);
        }
    }

    &:not(.negative):is(.primary, .basic, .tertiary) {
        // Primary buttons
        &.primary:not(.basic) {
            background-color: var(--fg-primary);

            &:not(.loading) {
                color: var(--ky-basic-white);
            }

            &:hover {
                background-color: var(--fg-primary-hover);
            }

            &:hover:not(.icon), &:active:not(.icon) {
                background-color: var(--fg-primary-hover);
            }
        }
        // Secondary buttons
        &.basic {
            border: 1px solid var(--ky-blue-40);

            &:not(.loading) {
                color: var(--fg-primary);
            }

            &:hover, &:active, &:focus {
                border: 1px solid var(--ky-blue-60);
            }
        }

        &.tertiary:not(.icon):not(.loading) {
            color: var(--fg-primary);
        }

        &:hover:not(.icon), &:active:not(.icon) {
            background-color: var(--bg-secondary-hover);
        }
    }

    &.negative {
        &:not(.basic) {
            background-color: var(--fg-error);

            &:not(.loading) {
                color: var(--ky-basic-white);
            }

            &:hover {
                background-color: var(--fg-error-hover);
            }
        }

        &.basic {
            border-color: var(--fg-error);

            &:not(.loading) {
                color: var(--fg-error);
            }

            &:hover {
                color: var(--fg-error-hover);
                border-color: var(--fg-error-hover);
            }
        }

        &:hover, &:active {
            background-color: var(--fg-error-hover);
        }
    }
    // Generic hovering effects
    &:hover, &:active {
        box-shadow: unset;
    }
    // Add pink outline on active and focus
    &:focus, &:active, &:focus-visible {
        box-shadow: 0px 0px 0px 1px var(--ky-basic-white), 0px 0px 0px 2px var(--bg-focussed) !important;
    }
}