.portalList {
    color: var(--ky-blue-100);
}

.noneFound {
    margin-top: var(--spacing-lg);
}

.list {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
    overflow-y: auto;
    max-height: 400px;

    > .portalCard {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: var(--spacing-sm);
        padding: var(--spacing-xs);
        border-radius: var(--spacing-sm);
        transition: background-color 0.2s;
        background-color: var(--ky-basic-white);

        &:global(.clickable):hover {
            background-color: var(--fg-hover);
        }

        > :global(.loader) {
            width: var(--avatar-size-m); // Loader causes a buggy overflow x
            flex: none;
        }

        > img {
            border-radius: var(--spacing-xs);
            border: var(--ky-black-10) solid 1.25px;
            background-color: var(--ky-basic-white);
        }

        > .portalDetails {
            flex-grow: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            > h5 {
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            > span { // Weak text
                font-size: 12px;
                color: var(--ky-black-50);
            }
        }

        > .suspended {
            color: var(--fg-error)
        }
    }
}
