// Make icon message more compact
.message:global(.ui.ui.ui) {
    padding: var(--spacing-sm) var(--spacing-md);
    gap: var(--spacing-sm);
    align-items: start;
    border-radius: var(--spacing-sm);
    background-color: var(--bg-signal-neutral);
    box-shadow: unset;
    border: var(--border-signal-neutral);
    color: var(--fg-body) !important;

    p {
        margin: 0 !important;
    }

    ul {
        margin-top: 0 !important;
    }

    &.withIcon {
        padding-left: var(--spacing-sm);
    }

    &:global(.compact) {
        margin: 2px 0;
    }

    > :global(i.icon) {
        color: var(--fg-signal-neutral-icon);
        margin: 2px;
        font-size: 16px;
        opacity: 1;
    }

    &:global(.error.ui) {
        background-color: var(--bg-signal-error);
        border: var(--border-signal-error);

        > i:global(.icon) {
            color: var(--fg-signal-error-icon);
        }
    }

    &:global(.success.ui) {
        background-color: var(--bg-signal-success);
        border: var(--border-signal-success);

        > i:global(.icon) {
            color: var(--fg-signal-success-icon);
        }
    }

    &:global(.info.ui) {
        background-color: var(--bg-signal-info);
        border: var(--border-signal-info);

        > i:global(.icon) {
            color: var(--fg-signal-info-icon);
        }
    }


    &:global(.warning.ui) {
        background-color: var(--bg-signal-warning);
        border: var(--border-signal-warning);

        > i:global(.icon) {
            color: var(--fg-signal-warning-icon);
        }
    }
}
