.welcome {
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05);
    background-color: var(--ky-basic-white);
    border-radius: var(--spacing-xl);
    padding: var(--spacing-xl);
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    gap: 8px;
    color: var(--ky-blue-100);

    > .signOutButton {
        position: absolute;
        top: var(--spacing-lg);
        right: var(--spacing-lg);
    }

    > * {
        margin: 0;
    }

    > div {
        width: 100%;
    }

    .filter {
        margin-bottom: var(--spacing-sm);
    }
}
