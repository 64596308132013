.emptyState {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    > img {
        max-width: 240px;
    }

    > h4, div {
        margin-top: 0;
        max-width: 400px;
        white-space: normal;
        word-wrap: break-word;
        text-align: center;
    }

    > h4, div:last-child {
        margin-bottom: 8px;
    }
}
