.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: max-content;
    height: 100%;
    max-width: 400px;
    margin: 0 auto;
    gap: 16px;

    > div { // Give all items an equal space so the portal list is always completely centered
        flex: 1;
    }

    > .warnings {
        display: flex;
        flex-direction: column;

        > div { // Put the warnings at the bottom of their container so it's just above the portal list
            margin-top: auto;

            > :global(.ui.ui.ui.message) { // And give inline messages a shadow
                box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05);
            }
        }
    }
}
