:root {
    // Generic Colors (Background / Foreground)
    --bg-primary: var(--ky-blue-10);
    --fg-primary: var(--ky-blue-60);
    --fg-primary-hover: var(--ky-blue-70);
    --bg-secondary-hover: var(--ky-blue-05);
    --bg-body: var(--ky-grey-40);
    --fg-passive: var(--ky-black-20);
    --fg-hover: var(--ky-black-05);
    --bg-focussed: var(--ky-pink-40);
    // Signal colors
    --bg-signal-success: var(--ky-green-05);
    --fg-signal-success-icon: var(--ky-green-60);
    --border-signal-success: 1px solid var(--ky-green-40);
    --bg-signal-info: var(--ky-lightblue-05);
    --fg-signal-info-icon: var(--ky-lightblue-60);
    --border-signal-info: 1px solid var(--ky-lightblue-40);
    --bg-signal-warning: var(--ky-orange-5);
    --fg-signal-warning-icon: var(--ky-orange-60);
    --border-signal-warning: 1px solid var(--ky-orange-40);
    --bg-signal-error: var(--ky-red-05);
    --fg-signal-error-icon: var(--ky-red-60);
    --border-signal-error: 1px solid var(--ky-red-40);
    --bg-signal-neutral: var(--ky-black-05);
    --fg-signal-neutral-icon: var(--ky-black-70);
    --border-signal-neutral: 1px solid var(--ky-black-40);
    // Status Colors (Background / Foreground)
    --fg-error: var(--ky-red-70);
    --fg-error-hover: var(--ky-red-80);
    // Tooltip Colors (Background / Foreground)
    --bg-tooltip: var(--ky-black-70);
    --fg-tooltip: var(--ky-black-40);
    --fg-tooltip-hover: var(--ky-black-60);
    // Spacings
    --spacing-xs: 4px;
    --spacing-sm: 8px;
    --spacing-md: 12px;
    --spacing-lg: 16px;
    --spacing-xl: 24px;
    --spacing-2xl: 32px;
    --spacing-3xl: 48px;
    --spacing-4xl: 64px;
    // Primitives (Do not comment unused variables)
    --ky-basic-white: white;
    --ky-black-05: #f4f4f4;
    --ky-black-10: #e6e6e6;
    --ky-black-20: #cccccc;
    --ky-black-40: #999999;
    --ky-black-50: #808080;
    --ky-black-60: #666666;
    --ky-black-70: #4d4d4d;
    --ky-blue-05: #eaf1ff;
    --ky-blue-10: #dce6fd;
    --ky-blue-40: #6495f6;
    --ky-blue-60: #2a50e6;
    --ky-blue-70: #223cd3;
    --ky-blue-100: #1d2561;
    --ky-green-05: #f0fdf4;
    --ky-green-40: #4ade80;
    --ky-green-60: #16a34a;
    --ky-grey-40: #f8f8f6;
    --ky-lightblue-05: #f1f7fd;
    --ky-lightblue-40: #55d2ff;
    --ky-lightblue-60: #1198f9;
    --ky-orange-5: #fffbec;
    --ky-orange-40: #ffbc32;
    --ky-orange-60: #f58700;
    --ky-pink-40: #ec7cee;
    --ky-red-05: #ffeff2;
    --ky-red-40: #ff617e;
    --ky-red-60: #f90643;
    --ky-red-70: #d20039;
    // Avatar sizes
    --avatar-size-s: 24px;
    --avatar-size-m: 32px;
    --avatar-size-l: 40px;
    --avatar-size-xl: 64px;
}
