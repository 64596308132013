.iconButton:global(.ui.ui.ui.ui.ui.icon.button) {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 0;
    margin: 0;

    &.sm {
        font-size: 16px;
        height: 24px;
        width: 24px;
    }

    &.md {
        font-size: 20px;
        height: 32px;
        width: 32px;
    }

    &.lg {
        font-size: 24px;
        height: 40px;
        width: 40px;
    }
}
